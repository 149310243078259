import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Multi from '../views/Multi.vue'
import Reset from '../views/Reset.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Selecteer'
    }
  },
  // {
  //   path: '/multi',
  //   name: 'Multi',
  //   component: Multi,
  //   meta: {
  //     title: 'Selecteer'
  //   }
  // },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      title: 'Wachtwoord vergeten'
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Selecteer';

  next();
})

export default router
