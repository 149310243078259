<template>
    <LoginSelector msg="Welcome to Your Vue.js App"/>
</template>

<script>
// @ is an alias to /src
import LoginSelector from '@/components/LoginSelector.vue'

export default {
  name: 'Home',
  components: {
    LoginSelector
  }
}
</script>

<style>
body, html, #app {
  height: 100%;
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;
  font-size: 12px;
  font-size: unset !important;
}

.bg {
  /* The image used */
  background-image: url('~@/assets/img/background.jpg');

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg.kon {
  /* The image used */
  background-image: url('~@/assets/img/background_kon.png');
}

.container {
  background-color: white;
  height: 490px;
  width: 490px;
  display: flex;  
  flex-direction: column;
}

header {
  margin-top: auto;
}

header h1 {
  font-family: 'Playfair Display',Arial,Helvetica,sans-serif;
  font-weight: 400;
  font-size: 2em;
  text-align: center;
  margin: 0 0 15px 0;
}

header h3 {
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 1.2em;
  color: #1e53bc;
  font-size: 1em;
}

.form-wrapper {
  margin-bottom: auto;
}

.form-wrapper.end {
  align-items: flex-end;
}

.form-wrapper.center {
  align-items: center;
}

.form-wrapper.extra-margin {
  margin-top: 1.7em;
}


.platform-item img {
  height: 60px;
  padding: 5px;
}

header img {
  width: 60px;
  height: 60px;
}

.logo-wrap {
  width: 100%;
  text-align: center;
}

.wrapper {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.input-label {
  display: block;
  padding-bottom: 0.3em;
  font-weight: 400;
  font-size: 12px;
  width:100%;
  margin-top: 15px;
}

input[type=email] {
  width: 100%;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  border-color: #2c2c2c;
  font-size: 1.1em;
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;
  padding: 5px 8px;
  box-sizing: border-box;
}

.platform-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 5px 10px;
  padding-left: 20px;
  font-size: 1.2em;
  font-weight: 600;
  color: #000000a8
}

.platform-item:hover {
    background-color: #1e53bc1f;
    cursor: pointer;
}

.platform-item:active {
    background-color:#1e53bc44;
    cursor: pointer;
}

footer {
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;
  margin: auto 0 15px 15px;
}

ul {
  list-style-type: none;
  margin-left:0px;
  padding-left:0px;
  display: inline-block;
}

input[type=submit] {
  width: 50%;
  border-radius: 2px;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 10px;
  font-size: 0.7em;
  background-color: #1e53bc;
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;;
  color: white;
  padding: 13px 16px;
}

input[type=submit]:hover {
  background-color: #184296;
  cursor: pointer;
}

input[type=submit]:focus {
  outline: none;
}

.error-message {
  width: 100%;
  color: rgb(248, 51, 60);
  font-size: 0.9em;
  margin: 10px auto;
  height: 19px;
}

a {
  color: #1e53bc;
  text-decoration: underline;
  cursor: pointer;
}

</style>
