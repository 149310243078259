<template>
    <ResetPassword msg="Welcome to Your Vue.js App"/>
</template>

<script>
// @ is an alias to /src
import ResetPassword from '@/components/ResetPassword.vue'

export default {
  name: 'Home',
  components: {
    ResetPassword
  }
}
</script>

<style>


</style>
