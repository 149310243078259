<template>
  <div class="bg">
    <template v-if="step === 1">
      <div class="container">
        <header>
          <h1>Welkom</h1>
        </header>
        <form action="#" class="form-wrapper wrapper end" @submit.prevent="onSubmit" novalidate="true">
          <label for="username" class="input-label">Emailadres</label>
          <input tabindex="1" name="username" type="email" v-model="email">
          <div class="error-message">
            <span v-if="errors.length">{{ errors[0] }}</span>
          </div>
          <input tabindex="2" class="submit" name="submit" type="submit" value="Inloggen">
        </form>
      </div>
    </template>

    <template v-if="step === 2">
      <div class="container">
        <header>
          <div class="logo-wrap">
            <img :src="getImageUrl(getPlatformDataNew[0].login_logo)" />
          </div>
          <h1>Welkom</h1>
          <h3>{{ email }}</h3>
          <div class="form-wrapper wrapper center extra-margin">
            <input tabindex="2" @click="doLogin(getPlatformDataNew[0].realm, getPlatformDataNew[0].name, email)"
              class="submit" name="submit" type="submit" value="Inloggen">
          </div>
        </header>
        <footer><a @click.prevent="otherAddress">Ander emailadres gebruiken?</a></footer>
      </div>

    </template>
    <template v-if="step === 3">
      <div class="container">
        <header>
          <h1>Platform kiezen</h1>
          <h3>{{ email }}</h3>
        </header>
        <div v-for="item in getPlatformDataNew" :key="item.name" class="platform-wrapper wrapper">
          <!-- <div  class="platform-item" @click="doLogin(item.name, email)"> -->
          <div class="platform-item" @click="doLogin(item.realm, item.name, item.email)">
            <img :src="getImageUrl(item.login_logo)" />
            {{ item.name }}
          </div>
        </div>
        <footer><a @click.prevent="otherAddress">Ander emailadres gebruiken?</a></footer>
      </div>
    </template>
  </div>
</template>

<script>

// const platforms = [
//   {'name': 'Improvers', 'logo': 'logo-improvers.svg', 'realm': 'improvers', 'sort': 1, 'url': 'https://miami.konnektor.nl/'},
//   {'name': 'Certified', 'logo': 'logo-certified.png', 'realm': 'certified', 'sort': 2, 'url': 'https://miami.konnektor.nl/'}
// ]

export default {
  name: 'LoginSelectorMulti',
  data() {
    return {
      email: null,
      step: 0,
      errors: [],
      found_platforms: [],
      found: null,
      found_result: null,
    }
  },
  methods: {
    onSubmit() {
      this.errors = [];

      if (!this.email) {
        this.errors.push('Vul emailadres in');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Vul geldig emailadres in');
      }

      if (!this.errors.length) {
        this.searchUserNew(this.email)
        // if (!this.searchUserNew(this.email)) {
        if (this.found_result === false) {
          this.errors.push('Vul geldig emailadres in');
        }
      }

      // e.preventDefault();
    },
    otherAddress() {
      // this.removeFavicon()

      this.step = 1;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    searchUserNew(email) {
      let encoded_email = encodeURIComponent(email)
      fetch(`${process.env.VUE_APP_SEARCH}?email=${encoded_email}`).then((response) => response.json()).then(
        (val) => {
          this.found = val.map((el) => {
            // get realm name (part after last '/')
            // let name = el.realm.match(/\/([^/]+)\/?$/)[1];
            return {
              email: el.email,
              realm: el.realm,
              name: el.name,
              login_logo: el.login_logo
            }
          });
          console.log(this.found);
          if (!this.found.length) {
            this.step = 1
            this.found_result = false
          } else {
            this.email = email
            localStorage.setItem("selector-data", JSON.stringify({ "email": this.email }))
            this.found_platforms = this.found
            if (this.found.length === 1) {
              this.step = 2
              const img = this.getPlatformDataNew[0].logo
              this.setFavicon(img);
            } else {
              this.step = 3
              // this.removeFavicon()
            }
            this.found_result = true
          }
        })
    },
    getImageUrl(img) {
      if (img) {
        const f = `${process.env.VUE_APP_S3_LOGO}${img}`
        return f;
      }
      return `${process.env.VUE_APP_S3_LOGO}logo-konnektor.png`
    },
    setFavicon(img) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.getImageUrl(img);
    },
    removeFavicon() {
      const elem = document.querySelector("link[rel~='icon']");
      elem.href = document.createElement("canvas").toDataURL("image/x-icon");
    },
    doLogin(realm, realm_name, email) {
      const client_id = new URL(window.location.href).searchParams.get('client_id') || 'teleknowledge';
      const redirect_base = new URL(window.location.href).searchParams.get('redirect_uri') || 'tkapp://sso';
      const redirect_uri = this.getRedirectUri(redirect_base, realm_name);
      const scope = new URL(window.location.href).searchParams.get('scope') || 'openid';
      const response_type = new URL(window.location.href).searchParams.get('response_type') || 'code';
      const last = realm.slice(-1)
      if (!last === '/') {
        realm += '/';
      }
      let encoded_email = encodeURIComponent(email)
      const loginUrl = `${realm}/protocol/openid-connect/auth?scope=${scope}&response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&username=${encoded_email}`
      window.location.href = loginUrl;
    },
    getRedirectUri(redirect_uri, realm_name) {
      const is_konnektor_backend = redirect_uri.includes("api.konnektor.nl");
      return is_konnektor_backend ? `${redirect_uri}%2F${realm_name.toLowerCase()}` : `${redirect_uri}%3Frealm=${realm_name.toLowerCase()}`
    },
  },

  mounted() {
    const selectorData = this.stored
    if (selectorData) {
      const parsed = JSON.parse(selectorData)
      this.email = parsed.email
    }
    this.searchUserNew(this.email)
  },
  computed: {
    stored() {
      return localStorage.getItem("selector-data");
    },
    getPlatformDataNew() {
      return this.found.map((el) => {
        return (

          { ...el }
        )
      }).sort((a, b) => a.sort - b.sort)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
