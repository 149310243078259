<template>
  <div class="page" :class="[realm]">
    <div class="surround" v-if="step === 1">
      <div class="container">

          <header>
            <img :src="get_logo"/>
            <h1>Wachtwoord vergeten</h1>
          </header>
          <form action="#" class="form-wrapper wrapper end" @submit.prevent="onSubmit" novalidate="true">
              <label for="username" class="input-label">Emailadres</label>
              <input tabindex="1" name="username" type="email" v-model="email">
              <div class="error-message">
                <span v-if="errors.length">{{errors[0]}}</span>
              </div>
              <input tabindex="2" class="submit" name="submit" type="submit"  value="Verstuur">
          </form>
      </div>
    </div>
        <div class="surround" v-if="step === 2">
      <div class="container">
          <header>
            <img :src="get_logo"/>
            <h1>Wachtwoord verstuurd</h1>
          </header>
          <form action="#" class="form-wrapper wrapper end" @submit.prevent="onRedirect" novalidate="true">
              <div>We hebben je een email gestuurd met hierin jouw nieuwe wachtwoord.
              </div>
              <div class="error-message">
                <span v-if="errors.length">{{errors[0]}}</span>
              </div>
              <input v-if="!close" tabindex="2" class="submit" name="submit" type="submit" value="Inloggen">
          </form>
      </div>
    </div>
  </div>
</template>

<script>
// const platforms = [
//   {'name': 'Improvers', 'logo': 'logo-improvers.svg', 'realm': 'improvers', 'sort': 1, 'url': 'https://miami.konnektor.nl/'},
//   {'name': 'Certified', 'logo': 'logo-certified.png', 'realm': 'certified', 'sort': 2, 'url': 'https://miami.konnektor.nl/'}
// ]

export default {
  name: "ResetPassword",
  data() {
    return {
      email: null,
      realm: null,
      step: 1,
      errors: [],
      close: null
      // found_platforms: [],
      // found: null,
      // found_result: null,
    };
  },
  methods: {
    onSubmit() {

      this.errors = [];

      if (!this.email) {
        this.errors.push('Vul emailadres in');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Vul geldig emailadres in');
      }

      if (!this.errors.length) {
        fetch(`${process.env.VUE_APP_RESET}?email=${this.email}&realm=${this.realm}`).then((response) => response.json()).then(
          (val) => {
              console.log('result', val)
          })
          this.step = 2;
        }
    },
    onRedirect() {
      if (!this.close) {
        const loc = new URL(window.location.href).searchParams.get('redirect')
        const decoded = decodeURIComponent(loc)
        window.location = decoded
      } else {
        console.log('close')
      }

    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setFavicon() {
      const favicon = document.getElementById("favicon");
      if (this.realm === 'certified') {
        favicon.href = "/img/logo-certified.png";
      } else {
        favicon.href = "/img/logo-improvers.svg";
      }

    }
  },
  mounted() {
    this.realm = new URL(window.location.href).searchParams.get("realm");
    this.close = new URL(window.location.href).searchParams.get("close");
    this.setFavicon()
  },
  computed: {
    get_logo() {
      if (this.realm === 'improvers') {
        return "/img/logo-improvers.svg"
      } else {
        return "/img/logo-certified.png"
      }
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair Display&display=swap');

body, html, #app {
  height: 100%;
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat',Arial,Helvetica,sans-serif;
  font-size: 12px;
  font-size: unset !important;
}

header h1 {
  font-family: 'Playfair Display',Arial,Helvetica,sans-serif;
  font-weight: 400;
  font-size: 2em;
  text-align: center;
  margin: 0 0 15px 0;
}

.page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
  background-color: white;
  height: 490px;
  width: 490px;
  display: flex;  
  flex-direction: column;
}

.improvers {
  background: #1e53bc;
}

.certified {
  background: #45e4bf;
}

header {
    vertical-align: middle;
    text-align: center;
    width: 100%;
}



</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
